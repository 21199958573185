import I18n from "./../utils/I18n";

const errors = {
	LOGIN: {
		NO_ADMIN: () => {
			return { errorCode: 403, errorMessage: I18n.t("LOGIN.NO_ADMIN") };
		},
	},
};

export default errors;
