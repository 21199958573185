import HttpService from "./HttpService";

const Constants = require("../views/constants/Constants");
const WEB = "Web";

export default class UserService {

	// login
	static login(email, pass, cb, errCb) {
		HttpService.httpCall(
			"POST",
			Constants.API_ROUTES.USER.LOGIN,
			{
				clientType: WEB,
				email: email,
				password: pass,
			},
			undefined,
			cb,
			errCb
		);
	}

	// login with facebook
	static loginFb(facebookId, fbToken, email, cb, errCb) {
		HttpService.httpCall(
			"POST",
			Constants.API_ROUTES.USER.LOGIN_FB,
			{
				email: email,
				clientType: WEB,
				facebookId: facebookId,
				facebookToken: fbToken,
			},
			undefined,
			cb,
			errCb
		);
	}

	// login with google
	static loginGoogle(googleId, googleToken, email, cb, errCb) {
		HttpService.httpCall(
			"POST",
			Constants.API_ROUTES.USER.LOGIN_GOOGLE,
			{
				email: email,
				clientType: WEB,
				googleId: googleId,
				googleToken: googleToken,
			},
			undefined,
			cb,
			errCb
		);
	}

	// get all users
	static getAll(token, pagination, sort, type, filter, start, end, state, avatar, cb, errCb) {
		const path = new URL(Constants.API_ROUTES.USER.GET_ALL);

		Object.keys(pagination).forEach((key) =>
			path.searchParams.append(key, pagination[key])
		);

		const sortParam = sort.reduce(
			(accum, { column, ascending }, i) =>
				accum.concat(`${i === 0 ? "" : ","}${column}:${ascending ? "asc" : "desc"}`),
			""
		);
		path.searchParams.append("sort", sortParam);

		if(filter)
			path.searchParams.append("filter", filter);

		if(avatar)
			path.searchParams.append("avatar", avatar);

		if(start)
			path.searchParams.append("start", new Date(start).toISOString());

		if(end)
			path.searchParams.append("end", new Date(end).toISOString());

		if(state)
			path.searchParams.append("state", state);

		if(type)
			path.searchParams.append("type", type);

		HttpService.httpCall("GET", path, undefined, token, cb, errCb);
	}

	// get current user
	static getByToken(token, cb, errCb) {
		HttpService.httpCall("GET", Constants.API_ROUTES.USER.GET_TOKEN, undefined, token, cb, errCb);
	}

	// get user by id
	static getById(token, userId, avatar, cb, errCb) {
		HttpService.httpCall("GET", Constants.API_ROUTES.USER.GET(userId, avatar), undefined, token, cb, errCb);
	}

	static getTrophies(token, userId, cb, errCb) {
		HttpService.httpCall("GET", Constants.API_ROUTES.USER.GET_TROPHIES(userId), undefined, token, cb, errCb);
	}

	// create user
	static signup(firstName, lastName, phone, email, dob, password, cb, errCb) {
		HttpService.httpCall(
			"POST",
			Constants.API_ROUTES.USER.SIGNUP,
			{
				firstName: firstName,
				lastName: lastName,
				phone: phone,
				email: email,
				dob: dob,
				password: password,
			},
			undefined,
			cb,
			errCb
		);
	}

	// activate user account
	static activateAccount(userId, code, cb, errCb) {
		HttpService.httpCall("POST", Constants.API_ROUTES.USER.ACTIVATE(userId, code), undefined, undefined, cb, errCb);
	}

	// activate user account while an admin (no code required)
	static activateAccountAdmin(token, userId, cb, errCb) {
		HttpService.httpCall("POST", Constants.API_ROUTES.USER.ACTIVATE_ADMIN(userId), undefined, token, cb, errCb);
	}

	// change language
	static editLanguage(token, language, cb, errCb) {
		HttpService.httpCall(
			"PUT",
			Constants.API_ROUTES.USER.EDIT_LANGUAGE,
			{
				language: language,
			},
			token,
			cb,
			errCb
		);
	}

	// send password recovery email
	static sendPasswordRecoveryEmail(/*name,*/ email, cb, errCb) {
		HttpService.httpCall(
			"POST",
			Constants.API_ROUTES.USER.SEND_PASSWORD_RECOVERY_EMAIL,
			{
				// name: name,
				email: email,
			},
			undefined,
			cb,
			errCb
		);
	}

	// change password
	static editPassword(token, oldPassword, password, cb, errCb) {
		HttpService.httpCall(
			"POST",
			Constants.API_ROUTES.USER.EDIT_PASSWORD,
			{
				oldPassword: oldPassword,
				password: password,
			},
			token,
			cb,
			errCb
		);
	}

	// recover password
	static recoverPassword(userId, password, code, cb, errCb) {
		HttpService.httpCall(
			"POST",
			Constants.API_ROUTES.USER.RECOVER_PASSWORD(userId, code),
			{
				password: password,
			},
			undefined,
			cb,
			errCb
		);
	}

	// make admin
	static makeAdmin(token, userId, cb, errCb) {
		HttpService.httpCall(
			"PUT",
			Constants.API_ROUTES.USER.MAKE_ADMIN(userId),
			undefined,
			token,
			cb,
			errCb
		);
	}

	// block user
	static blockUser(token, userId, cb, errCb) {
		HttpService.httpCall(
			"POST",
			Constants.API_ROUTES.USER.BLOCK(userId),
			undefined,
			token,
			cb,
			errCb
		);
	}

	// block user
	static unBlockUser(token, userId, cb, errCb) {
		HttpService.httpCall(
			"POST",
			Constants.API_ROUTES.USER.UNBLOCK(userId),
			undefined,
			token,
			cb,
			errCb
		);
	}

	// edit user
	static edit(token, userId, userName, email, role, points, pointsSeason, rank, trophies, successful, unsuccessful, cb, errCb) {
		HttpService.httpCall(
			"PUT",
			Constants.API_ROUTES.USER.EDIT(userId),
			{
				userName: userName,
				email: email,
				points: points,
				pointsSeason: pointsSeason,
				rank: rank,
				role: role,
				trophies: trophies,
				successfulPredictions: successful,
				unSuccessfulPredictions: unsuccessful
			},
			token,
			cb,
			errCb
		);
	}

	// delete user
	static delete(token, userId, cb, errCb) {
		HttpService.httpCall("DELETE", Constants.API_ROUTES.USER.DELETE(userId), undefined, token, cb, errCb);
	}
}
