import React, { Component } from "react";
import { withRouter, Redirect } from "react-router";

import ErrorCard from "../../../utils/cards/ErrorCard/ErrorCard";
import MessageCard from "../../../utils/cards/MessageCard/MessageCard";
import Spinner from "../../../utils/Spinner";
import I18n from "../../../utils/I18n";
import UserService from "../../../../services/UserService";
import CookieService from "../../../../services/CookieService";

import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Form,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
} from "reactstrap";

const Constants = require("../../../constants/Constants");

class Recover extends Component {
	constructor(props) {
		super(props);

		this.state = {
			error: false,
			loading: false,
			message: false,
			password: "",
			passwordConfirm: "",
		};
	}

	// change pass input value
	updatePass = (event) => {
		this.setState({ password: event.target.value, error: false, message: false });
	};

	// change passConfirm input value
	updatePassConfirm = (event) => {
		this.setState({ passwordConfirm: event.target.value, error: false, message: false });
	};

	// returns true if password an confirmPassword match
	isSendEnabled = () => {
		return this.state.password && this.state.password === this.state.passwordConfirm;
	};

	// update user password
	onPasswordChange = (event) => {
		event.preventDefault();
		const splitPath = this.props.history.location.pathname.split("/");

		const userId = splitPath[splitPath.length - 2];
		const code = splitPath[splitPath.length - 1];

		if (!userId || !code) return;

		const self = this;
		self.setState({ loading: true });
		UserService.recoverPassword(
			userId,
			this.state.password,
			code,
			async function () {
				self.setState({
					loading: false,
					password: "",
					passwordConfirm: "",
					message: I18n.t("PASSWORD_CHANGED"),
					error: false,
				});
			},
			function (err) {
				console.log(err);
				self.setState({ error: err, message: false, loading: false });
			}
		);
	};

	// return to login page
	onBack = () => {
		this.props.history.goBack();
	};

	render() {
		if (CookieService.get("token")) {
			return <Redirect to={Constants.ROUTES.DASHBOARD} />;
		}

		const loading = this.state.loading;
		const disabled = !this.isSendEnabled();
		return (
			<div className={loading ? "app flex-row align-items-center Loading" : "app flex-row align-items-center"}>
				<Container>
					{Spinner.render(loading)}
					<Row className="justify-content-center">
						<Col md="6" lg="7" xl="8">
							{this.renderRecoverCard(disabled)}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}

	renderMessageCard = () => {
		const message = this.state.message;
		return message && MessageCard.render(message);
	};

	renderErrorCard = () => {
		const error = this.state.error;
		return error && ErrorCard.render(error.errorCode, error.errorMessage);
	};

	renderRecoverCard = (disabled) => {
		return (
			<Card className="mx-4">
				<CardBody className="p-4">
					<Form onSubmit={this.onPasswordChange} id="change-pass-form">
						<h1 style={{ textAlign: "center" }}>{I18n.t("CHANGE_PASSWORD.TITLE")}</h1>
						<p className="text-muted">{I18n.t("CHANGE_PASSWORD.BODY")}</p>
						{this.renderPasswordInput()}
						{this.renderPasswordConfirmInput(disabled)}
					</Form>

					<Row>
						<Col xs="6">{this.renderChangePasswordButton(disabled)}</Col>
						{/*<Col xs="6">{this.renderBackButton()}</Col>*/}
					</Row>

					<Row className="justify-content-center">{this.renderErrorCard()}</Row>
					<Row className="justify-content-center">{this.renderMessageCard()}</Row>
				</CardBody>
			</Card>
		);
	};

	renderPasswordInput = () => {
		return (
			<InputGroup className="mb-3">
				<InputGroupAddon addonType="prepend">
					<InputGroupText>
						<i className="icon-lock"></i>
					</InputGroupText>
				</InputGroupAddon>
				<Input type="password" placeholder={I18n.t("CHANGE_PASSWORD.PASS")} onChange={this.updatePass} />
			</InputGroup>
		);
	};

	renderPasswordConfirmInput = (disabled) => {
		return (
			<InputGroup className="mb-3">
				<InputGroupAddon addonType="prepend">
					<InputGroupText>
						<i className="icon-lock"></i>
					</InputGroupText>
				</InputGroupAddon>
				<Input
					type="password"
					placeholder={I18n.t("CHANGE_PASSWORD.CONFIRM_PASS")}
					className={disabled ? "PasswordRecoverConfirmInput BorderRed" : "PasswordRecoverConfirmInput"}
					onChange={this.updatePassConfirm}
				/>
			</InputGroup>
		);
	};

	renderChangePasswordButton = (disabled) => {
		return (
			<Button color="primary" className="px-4" type="submit" disabled={disabled} form="change-pass-form">
				{I18n.t("CHANGE_PASSWORD.BUTTON")}
			</Button>
		);
	};

	renderBackButton = () => {
		return (
			<Button color="link" className="backButton px-0"  onClick={this.onBack}>
				{I18n.t("CHANGE_PASSWORD.BACK")}
			</Button>
		);
	};
}

export default withRouter(Recover);
