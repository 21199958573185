import CookieService from "./CookieService";
const Constants = require("../views/constants/Constants");


export default class HttpService {
	static httpCall(method, url, body, token, cb, errCb, noRetry) {
		const data = {
			method: method,
			headers: {
				"Content-Type": "application/json",
				"Accept-Language": CookieService.get("language"),
				"version": "0.0.1",
				"clienttype": "Web"
			},
		};

		if (token) {
			data["headers"]["Authorization"] = token;
		}

		if (body) {
			data["body"] = JSON.stringify(body);
		}

		let statusCode;
		let respToken;
		fetch(url, data)
			.then((data) => {

				for (var pair of data.headers.entries()) {
					if (pair[0] === "authorization") {
						respToken = pair[1].replace("Bearer ", "");
					}
				}

				statusCode = data.status;
				return data.json();
			})
			.then((data) => {
				if (statusCode < 300) {
					if (data.data && data.data.session && data.data.session.refreshToken)
						CookieService.set("refreshToken", data.data.session.refreshToken);
					if (respToken) CookieService.set("token", respToken);
					return cb(data.data, respToken);
				} else {
					if (statusCode === 401 && !noRetry) {
						console.log("token expired, renewing...");

						HttpService.refreshToken(function () {
							console.log("retrying...");
							HttpService.httpCall(method, url, body, token, cb, errCb, true);
						}, errCb);
					} else {
						if (statusCode === 401 && noRetry) {
							CookieService.delete("token");
							CookieService.delete("refreshToken");
						}

						return errCb({ errorMessage: data.errorMessage, errorCode: statusCode });
					}
				}
			})
			.catch((err) => {
				if (err.errorCode === 401) CookieService.delete("token");

				console.log(err);
				if (!err.errorMessage) err = { errorMessage: err.message, errorCode: 404 };
				return errCb(err);
			});
	}

	static httpImageCall(method, url, formData, token, cb, errCb) {
		const data = {
			method: method,
			headers: {
				"version": "0.0.1",
				"clienttype": "Web"
			},
		};

		if (token) {
			data["headers"]["Authorization"] = token;
		}

		if (formData) {
			data["body"] = formData;
		}

		let statusCode;
		let respToken;
		fetch(url, data)
			.then((data) => {
				statusCode = data.status;
				return data.json();
			})
			.then((data) => {
				if (statusCode < 300) {
					return cb(data.data, respToken);
				} else {
					errCb({ errorCode: statusCode, errorMessage: data.errorMessage });
				}
			})
			.catch((err) => {
				if (err.errorCode === 401) {
					HttpService.refreshToken(function () {
						HttpService.httpImageCall(method, url, formData, token, cb, errCb);
					}, errCb);
				}

				console.log(err);
				if (!err.errorMessage) err = { errorMessage: err.message, errorCode: 404 };
				return errCb(err);
			});
	}

	static refreshToken(cb, errCb) {
		const token = CookieService.get("token");
		const refreshToken = CookieService.get("refreshToken");
		HttpService.httpCall(
			"POST",
			Constants.API_ROUTES.USER.REFRESH_TOKEN,
			{ refreshToken: refreshToken },
			token,
			cb,
			errCb,
			true
		);
	}
}