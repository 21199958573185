import React, { Component } from "react";
import { withRouter, Redirect } from "react-router";

import {
	Button,
	Card,
	CardGroup,
	Col,
	Form,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
	CardBody,
} from "reactstrap";

import "./Login.scss";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import AppleLogin from 'react-apple-login'

import ErrorCard from "../../utils/cards/ErrorCard/ErrorCard";
import Spinner from "../../utils/Spinner";
import I18n from "../../utils/I18n";
import UserService from "../../../services/UserService";
import CookieService from "../../../services/CookieService";
import Errors from "../../constants/Errors";

const Constants = require("../../constants/Constants");

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			message: false,
			error: false,
			email: "",
			password: "",
		};
	}

	componentDidMount() {
		this.setState({ loading: false, message: false, error: false, email: "", password: "" });
	}

	// log in with the server and verify that the user is an admin
	onLogin = (event) => {
		event.preventDefault();
		let self = this;
		self.setState({ loading: true });
		UserService.login(
			this.state.email,
			this.state.password,
			async function (userData, token) {
				const user = userData.user;
				if (user.role === "Admin") {
					CookieService.set("token", token);
					CookieService.set("language", user.language);

					I18n.changeLanguage(user.language);
					self.setState({ loading: false });
				} else {
					self.setState({ error: Errors.LOGIN.NO_ADMIN(), message: false, loading: false });
				}
			},
			function (err) {
				console.log(err);
				self.setState({ error: err, message: false, loading: false });
			}
		);
	};

	responseApple = (response) => {
		console.log(response);
	}

	responseFacebook = (response) => {
		const self = this;
		const facebookId = response.userID;
		const token = response.accessToken;
		const email = response.email;

		if (!facebookId || !token) return;

		self.setState({ loading: true });
		UserService.loginFb(
			facebookId,
			token,
			email,
			async function (userData, token) {
				const user = userData.user;
				if (user.role === "Admin") {
					CookieService.set("token", token);
					CookieService.set("language", user.language);

					I18n.changeLanguage(user.language);
					self.setState({ loading: false });
				} else {
					self.setState({ error: Errors.LOGIN.NO_ADMIN(), message: false, loading: false });
				}
			},
			function (err) {
				console.log(err);
				self.setState({ error: err, message: false, loading: false });
			}
		);
	};

	responseGoogle = (response) => {
		const self = this;

		if(!response || !response.profileObj) return;

		const googleId = response.googleId;
		const token = response.tokenId;
		const email = response.profileObj.email;

		//console.log(googleId)
		//console.log(token)
		//console.log(email)

		if (!googleId || !token) return;

		self.setState({ loading: true });
		UserService.loginGoogle(
			googleId,
			token,
			email,
			async function (userData, token) {
				const user = userData.user;
				if (user.role === "Admin") {
					CookieService.set("token", token);
					CookieService.set("language", user.language);

					I18n.changeLanguage(user.language);
					self.setState({ loading: false });
				} else {
					self.setState({ error: Errors.LOGIN.NO_ADMIN(), message: false, loading: false });
				}
			},
			function (err) {
				console.log(err);
				self.setState({ error: err, message: false, loading: false });
			}
		);
	};

	// sends an email with the recovery code
	toSendRecoveryEmailPage = () => {
		this.props.history.push(Constants.ROUTES.FORGOT);
	};

	// mail and password set
	isSendEnabled = () => {
		return this.state.email && this.state.password;
	};

	render() {
		if (CookieService.get("token")) {
			return <Redirect to={Constants.ROUTES.DASHBOARD} />;
		}

		const loading = this.state.loading;
		const disabled = !this.isSendEnabled();
		return (
			<div className={loading ? "app flex-row align-items-center Loading" : "app flex-row align-items-center"}>
				{Spinner.render(loading)}
				<div className="col-md-8 col-sm-4 loginImageContainer">
					<img className="loginImage" src={"assets/img/home.jpg"} alt="startup@creativecoefficient.net"></img>
				</div>
				<div className="col-md-4 col-sm-8 col-xs-12  loginCardContainer">
					<CardGroup className="loginCardGroup">{this.renderLoginCard(disabled)}</CardGroup>
				</div>
			</div>
		);
	}

	renderErrorCard = () => {
		const error = this.state.error;
		return error && ErrorCard.render(error.errorCode, error.errorMessage, true);
	};

	renderLoginCard = (disabled) => {
		return (
			<Card className="p-4 loginCard">
				<img
					className={this.state.error ? "logoSmall" : "logo"}
					src={"assets/img/logo.png"}
					alt={Constants.APP_LOGO}
				></img>
				<CardBody className="loginCardBody">
					<Form onSubmit={this.onLogin} id="login-form" disabled={disabled}>
						<h1 style={{ textAlign: "center" }}>{I18n.t("LOGIN.TITLE", { name: Constants.APP_NAME})}</h1>
						<p className="text-muted">{I18n.t("LOGIN.LOGIN")}</p>
						{this.renderEmailInput()}
						{this.renderPassInput()}
						<Row>
							<Col xs="6">{this.renderLoginButton(disabled)}</Col>
							<Col xs="6" className="text-right">
								{this.renderForgotButton()}
							</Col>
						</Row>
					</Form>
					<Row>
						<Col xs="12" m="12" className="text-center">
							<div>{this.renderErrorCard()}</div>
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	};

	renderEmailInput = () => {
		return (
			<InputGroup className="mb-3">
				<InputGroupAddon addonType="prepend">
					<InputGroupText>
						<i className="icon-user"></i>
					</InputGroupText>
				</InputGroupAddon>
				<Input
					type="text"
					placeholder={I18n.t("FIELDS.EMAIL")}
					onChange={(event) => {
						this.setState({ email: event.target.value, error: false, message: false });
					}}
				/>
			</InputGroup>
		);
	};

	renderPassInput = () => {
		return (
			<InputGroup className="mb-4">
				<InputGroupAddon addonType="prepend">
					<InputGroupText>
						<i className="icon-lock"></i>
					</InputGroupText>
				</InputGroupAddon>
				<Input
					type="password"
					placeholder={I18n.t("FIELDS.PASS")}
					autoComplete="on"
					onChange={(event) => {
						this.setState({ password: event.target.value, error: false, message: false });
					}}
				/>
			</InputGroup>
		);
	};

	renderGoogleButton = () => {
		return (
			<GoogleLogin
				className="GoolgeLogin"
				clientId={Constants.GOOGLE.CLIENT_ID}
				buttonText=""
				onSuccess={this.responseGoogle}
				onFailure={this.responseGoogle}
				render={(renderProps) => <div onClick={renderProps.onClick} className="fa fa-google"></div>}
			/>
		);
	};

	renderAppleButton = () => {
		return (
			<AppleLogin
				clientId={Constants.APPLE.CLIENT_ID}
				redirectURI={Constants.APPLE.REDIRECT_URL}
				callback={this.responseApple}
				usePopup={true}
				responseMode={"query"}
				responseType={"id_token"}
				render={(renderProps)=> {
					return <div className="fa fa-apple" onClick={renderProps.onClick}>
					</div>
				}}
			/>
		);
	};

	renderFbButton = () => {
		return (
			<FacebookLogin
				appId={Constants.FACEBOOK.APP_ID}
				fields="name,email,picture"
				callback={this.responseFacebook}
				render={(renderProps) => <div onClick={renderProps.onClick} className="fa fa-facebook"></div>}
			/>
		);
	};

	renderForgotButton = () => {
		return (
			<Button color="link" className="px-0" style={{ paddingTop: "0px" }} onClick={this.toSendRecoveryEmailPage}>
				{I18n.t("LOGIN.FORGOT_PASSWORD")}
			</Button>
		);
	};

	renderLoginButton = (disabled) => {
		return (
			<Button color="primary" className="px-4" type="submit" form="login-form" disabled={disabled}>
				{I18n.t("LOGIN.BUTTON")}
			</Button>
		);
	};
}

export default withRouter(Login);
