const API_URL = window._env_ && window._env_.REACT_APP_BACKEND ? window._env_.REACT_APP_BACKEND : process.env.REACT_APP_BACKEND;

module.exports = {
	APP_NAME: "predisport",
	APP_MAIL: "contact@predisport.com",
	APP_LOGO: "logo@predisport.net",

	API_ROUTES: {
		RESOURCES: {
			GET_RESOURCE: (resId) => API_URL + `/resource/${resId}`,
			UPDATE_USER_AVATAR: (userId) =>  `${API_URL}/resource/user/${userId}/avatar`,
			UPDATE_LEAGUE_AVATAR: (leagueId) =>  `${API_URL}/resource/league/${leagueId}/avatar`,
			UPDATE_TEAM_AVATAR: (teamId) =>  `${API_URL}/resource/team/${teamId}/avatar`,
		},
		LEAGUE: {
			GET_ALL: (addAvatar) => `${API_URL}/leagues/all?avatar=${addAvatar}`,
			UPDATE_NAME: (id) => `${API_URL}/leagues/${id}`,
			ADD: `${API_URL}/leagues/`,
			DELETE: (id) =>  `${API_URL}/leagues/${id}`,
		},
		TEAM: {
			GET_ALL: (leagueId) => `${API_URL}/teams/${leagueId ? leagueId : "all"}/teams`,
			UPDATE_NAME: (id) => `${API_URL}/teams/team/${id}`,
			UPDATE_DISPLAY_NAME_AND_COLORS: (id) => `${API_URL}/teams/display/${id}`,
			ADD: `${API_URL}/teams/team`,
			DELETE: (id) =>  `${API_URL}/teams/team/${id}`,
		},
		GAME: {
			POST_MOCK_GAME: `${API_URL}/games/mock`,
			GET: (id, avatar) => `${API_URL}/games/${id}?avatar=${avatar}`,
			GET_ALL: (team1, team2) => {
				if(team1 && team2) {
					return `${API_URL}/games/team/${team1}/team2/${team2}`;
				} else {
					if(team1) {
						return `${API_URL}/games/team/${team1}`;
					}

					if(team2) {
						return `${API_URL}/games/team/${team2}`;
					}
				}
				return `${API_URL}/games/`;
			},
			GET_POLL_ANSWERS: (gameId) => `${API_URL}/games/${gameId}/poll/all`,
			GET_STATES_ALL: () => `${API_URL}/games/states/all`,
			GET_UPDATE_GAME_STATE: (gameId, state) => `${API_URL}/games/${gameId}/state/${state}`,
		},
		FEEDBACK: {
			GET_ALL: `${API_URL}/user/feedback/all`,
		},
		REPORT: {
			GET_ALL: `${API_URL}/user/reports/all`,
		},
		USER: {
			GET_ALL: `${API_URL}/user/all`,
			GET_TOKEN: `${API_URL}/user/`,
			GET: (userId, avatar) => `${API_URL}/user/${userId}/admin?avatar=${avatar}`,
			GET_TROPHIES: (userId) => `${API_URL}/user/${userId}/trophies`,
			LOGIN: `${API_URL}/user/login`,
			LOGIN_FB: `${API_URL}/user/login/fb`,
			LOGIN_GOOGLE: `${API_URL}/user/login/google`,
			REFRESH_TOKEN: `${API_URL}/user/refreshToken`,
			SIGNUP: `${API_URL}/user/signup/`,
			ACTIVATE: (userId, code) => `${API_URL}/user/${userId}/activate/${code}`,
			ACTIVATE_ADMIN: (userId) => `${API_URL}/user/${userId}/activate/`,
			MAKE_ADMIN: (userId) => `${API_URL}/user/${userId}/admin/`,
			BLOCK: (userId) => `${API_URL}/user/${userId}/block/`,
			UNBLOCK: (userId) => `${API_URL}/user/${userId}/unblock/`,
			EDIT: (userId) => `${API_URL}/user/${userId}`,
			EDIT_LANGUAGE: `${API_URL}/user/language`,
			SEND_PASSWORD_RECOVERY_EMAIL: `${API_URL}/user/password/forgot`,
			RECOVER_PASSWORD: (userId, code) => `${API_URL}/user/${userId}/password/recover/${code}`,
			EDIT_PASSWORD: `${API_URL}/user/password`,
			DELETE: (userId) => `${API_URL}/user/${userId}`,
		},
	},
	ROUTES: {
		ACCOUNT_ACTIVATION: "/activateAccount",
		PASSWORD_RECOVER: "/passwordRecovery",
		FORGOT: "/forgot",
		LOGIN: "/",
		LEAGUES: "/leagues",
		FEEDBACK: "/feedback",
		GAMES: "/games",
		GAME: (gameId) => "/game/"+gameId,
		TEAMS: (leagueId) => "/teams/"+leagueId,
		USER: (userId) => "/user/"+userId,
		REPORTED: "/report",
		DASHBOARD: "/dashboard",
		MOCK_GAME: "/mockGame",
	},

	PAGINATION: {
		PAGE_SIZES: [10, 15, 20, 25, 50, 100],
	},

	USER_TYPES: {
		Admin: "Admin",
		Client: "Client"
	},

	TYPES: {
		TEXT: "Text",
		PARAGRAPH: "Paragraph",
		PASSWORD: "Password",
		DATE: "Date",
		EMAIL: "Email",
		PHONE: "Phone",
		NUMBER: "Number",
		BOOLEAN: "Boolean",
		CHECKBOX: "Checkbox",
	},

	GAME: {
		STATES_LIST: {
			scheduled: "scheduled",
			inprogress: "inprogress",
			complete: "complete",
			cancelled: "cancelled",
		},
		STATES: [
			{
				value: "scheduled",
				label: "Scheduled"
			},
			{
				value: "inprogress",
				label: "In Progress"
			},
			{
				value: "cancelled",
				label: "Cancelled"
			},
			{
				value: "complete",
				label: "Complete"
			}
		]
	},
	RESOURCE_TYPES: {
		AVATARS: "profilePics",
	},

	FACEBOOK: {
		APP_ID: "796995947921779",
		CLIENT_SECRET_KEY: "3c050eb9d2e524cc168ff3b36be7a7bc"
	},
	GOOGLE: {
		CLIENT_ID: "431737423239-7md93bk8qq80pi8k9qvs0i28m96mgjjv.apps.googleusercontent.com",
		CLIENT_SECRET_KEY: "oFYjIGBGdjPxVZAntFmN_63g",
	},
	APPLE: {
		CLIENT_ID: "TODO",
		REDIRECT_URL: "TODO"
	},

	DEFAULT_LANGUAGE: "en",
	AVAILABLE_LANGUAGES: ["en", "es"],
};
