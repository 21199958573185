import React, { Component } from "react";
import { withRouter, Redirect } from "react-router";

import "./Forgot.scss";

import ErrorCard from "../../../utils/cards/ErrorCard/ErrorCard";
import MessageCard from "../../../utils/cards/MessageCard/MessageCard";
import Spinner from "../../../utils/Spinner";
import I18n from "../../../utils/I18n";
import UserService from "../../../../services/UserService";
import CookieService from "../../../../services/CookieService";

import {
	Button,
	Card,
	CardBody,
	Col,
	CardGroup,
	Form,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
} from "reactstrap";

const Constants = require("../../../constants/Constants");

class Forgot extends Component {
	constructor(props) {
		super(props);

		this.state = {
			error: false,
			loading: false,
			email: "",
		};
	}

	// sends an email with the recovery code
	sendRecoveryEmail = () => {
		let self = this;
		self.setState({ loading: true });
		UserService.sendPasswordRecoveryEmail(
			//this.state.name,
			this.state.email,
			async function () {
				self.setState({ loading: false, error: false, email: "", message: I18n.t("SENT_EMAIL") });
			},
			function (err) {
				console.log(err);
				self.setState({ error: err, message: false, loading: false });
			}
		);
	};

	// password set
	isSendEnabled = () => {
		return this.state.email /*&& this.state.name*/;
	};

	// return to login page
	onBack = () => {
		this.props.history.goBack();
	};

	render() {
		if (CookieService.get("token")) {
			return <Redirect to={Constants.ROUTES.DASHBOARD} />;
		}

		const loading = this.state.loading;
		const disabled = !this.isSendEnabled();
		return (
			<div className={loading ? "app flex-row align-items-center Loading" : "app flex-row align-items-center"}>
				{Spinner.render(loading)}
				<div className="col-md-8 col-sm-4 forgotImageContainer">
					<img
						className="forgotImage"
						src={"assets/img/login-register.jpg"}
						alt="startup@creativecoefficient.net"
					></img>
				</div>
				<div className="col-md-4 col-sm-8 col-xs-12 forgotCardContainer">
					<CardGroup className="forgotCardGroup">{this.renderRecoveryEmailCard(disabled)}</CardGroup>
				</div>
			</div>
		);
	}

	renderMessageCard = () => {
		const message = this.state.message;
		return message && MessageCard.render(message, true);
	};

	renderErrorCard = () => {
		const error = this.state.error;
		return error && ErrorCard.render(error.errorCode, error.errorMessage, true);
	};

	renderRecoveryEmailCard = (disabled) => {
		return (
			<Card className="p-4 forgotCard">
				<img className="logo" src={"assets/img/logo.png"} alt="logo@creativecoefficient.net"></img>

				<CardBody className="forgotCardBody">
					<Form onSubmit={this.sendRecoveryEmail} id="recover-form">
						<h1 style={{ textAlign: "center" }}>{I18n.t("PASS_RECOVERY_EMAIL.TITLE")}</h1>
						<p className="text-muted">{I18n.t("PASS_RECOVERY_EMAIL.BODY")}</p>
						{this.renderEmailInput()}
					</Form>

					<Row>
						<Col xs="6">{this.renderSendRecoveryEMailButton(disabled)}</Col>
						<Col xs="6">{this.renderBackButton()}</Col>
					</Row>
					<Row className="justify-content-center">{this.renderErrorCard()}</Row>
					<Row className="justify-content-center">{this.renderMessageCard()}</Row>
				</CardBody>
			</Card>
		);
	};

	renderEmailInput = () => {
		return (
			<InputGroup className="mb-3">
				<InputGroupAddon addonType="prepend">
					<InputGroupText>@</InputGroupText>
				</InputGroupAddon>
				<Input
					type="text"
					placeholder={I18n.t("PASS_RECOVERY_EMAIL.EMAIL")}
					onChange={(event) => {
						this.setState({ email: event.target.value, error: false, message: false });
					}}
				/>
			</InputGroup>
		);
	};

	renderSendRecoveryEMailButton = (disabled) => {
		return (
			<Button color="primary" className="px-4" type="submit" form="recover-form" disabled={disabled}>
				{I18n.t("PASS_RECOVERY_EMAIL.SEND_EMAIL")}
			</Button>
		);
	};

	renderBackButton = () => {
		return (
			<Button color="link" className="backButton px-0" onClick={this.onBack}>
				{I18n.t("PASS_RECOVERY_EMAIL.BACK")}
			</Button>
		);
	};
}

export default withRouter(Forgot);
